import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Header = ({ logo }) => {
  return (
    <header className="text-gray-700 body-font">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <AnchorLink
          href="#sobre"
          className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
        >
          <div style={{ width: "50px", height: "50px" }}>{logo}</div>
          <span className="ml-3 text-xl">gabriela muttoni</span>
        </AnchorLink>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
          <AnchorLink href="#sobre" className="mr-5 hover:text-gray-900">
            Início
          </AnchorLink>
          <AnchorLink href="#atendimentos" className="mr-5 hover:text-gray-900">
            Atendimentos
          </AnchorLink>
          <AnchorLink href="#depoimentos" className="mr-5 hover:text-gray-900">
            Depoimentos
          </AnchorLink>
        </nav>
        <a
          href="http://wa.me/5551993093330"
          target="_blank"
          className="inline-flex items-center bg-gray-200 border-0 py-1 px-3 focus:outline-none hover:bg-gray-300 rounded text-base mt-4 md:mt-0"
        >
          Contato
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            className="w-4 h-4 ml-1"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
    </header>
  )
}

export default Header
