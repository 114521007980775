/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "../../styles/tailwind.css"

import Header from "../Header"
import Footer from "../Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "gabriela-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 50, maxHeight: 50) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const logo = <Img fluid={data.file.childImageSharp.fluid} />

  return (
    <>
      <Header logo={logo} />
      <main>{children}</main>
      <Footer logo={logo} />
    </>
  )
}

export default Layout
